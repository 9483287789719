import React,  { useEffect }  from 'react'

const Banner =  (props) => {

    useEffect(() => {
        document.body.style.background =`#000000`  
    }, [])

    return(  
            <div className ='banner' >  
               <h3>Sitio no disponible</h3> 
            </div>
         )    
}
        
export default Banner