import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom"
import './styles/main.scss'


import Login from './components/Login'
import Home from './components/Administrador/Home'
import Nuevo from './components/Administrador/Nuevo/Nuevo'
import NuevoQr from './components/Administrador/Nuevo/NuevoQr'
import Modificar from './components/Administrador/Modificar/Modificar'

import ViewTemplate from './components/Templates/ViewTemplate'
import WhatsApp from './components/WhatsApp/index'


function App() {
    return (
          <Router>
              <Route path='/' exact component={Login}/>
             
              <Route path='/Home' exact component={Home}/>
              <Route path='/Nuevo' exact component={Nuevo}/>
              <Route path='/NuevoQr' exact component={NuevoQr}/>
              <Route path='/Modificar' exact component={Modificar}/>

              <Route path='/:template' exact component={ViewTemplate}/> 
         
              <Route path='/unete-whatsapp-zermat' exact component={WhatsApp}/>   

          </Router>
    );
}

export default App;
