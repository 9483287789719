import axios from 'axios';
import url from './url';

const Api = {
    registroBitacoraQRAdm: async (idusuario,accion,dispositivo,navegador,ip, os) => {   
        var data = {
            'idusuario': idusuario,
            'accion': accion,
            'dispositivo': dispositivo,
            'navegador': navegador,
            'ip': ip,
            'os': os,   
        }
        return await axios.post(url.url+'/registroBitacoraQRAdm',data,) 
    },
    registroBitacoraQRUsr: async (template,dispositivo,navegador,ip, os) => {   
        var data = {
            'template': template,
            'dispositivo': dispositivo,
            'navegador': navegador,
            'ip': ip,
            'os': os,   
        }
        return await axios.post(url.url+'/registroBitacoraQRUsr',data,) 
    },



    
}
export default Api