import React from "react";
import ReactPlayer from 'react-player'

const Video = ({url}) => {
    return ( 
        <ReactPlayer      
            url={url}   
            muted ={true}
            loop={true}
            playing={true}
            onProgress = {0.15}   
            className='react-player'
        />   
    )
   
}

export default Video