import React, {Component} from 'react';
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink,MDBRow,MDBCol,MDBInput ,MDBBtn,MDBCard,MDBCardBody,MDBIcon 
,MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter

} from 'mdbreact';

import QRCode from 'qrcode.react';
import jwt from 'jwt-decode'


import controllerQR from '../../../../controllers/CodigoQR'
import controllerImg from '../../../../controllers/Imagenes'

import url from '../../../../api/url';



import PrevSitios from '../../PrevSitios'
import PrevVideos from '../../PrevVideos'
import PrevImagenMod from '../../PrevImagenMod'
import PrevImagen from '../../PrevImagen'
import PrevFooter from '../../PrevFooter'
import PrevFooterMod from '../../PrevFooterMod'

class ModTabs1 extends Component{    
    constructor(props){
    super(props);
        this.state=({
            activeItem: '1',
            modal: false,
            modalMessage: '',

            qr:false,
            codigoQr:'',
         
            nombre:'',
            descripcion:'',
            fecini:'',
            fecfin:'',

            nombreSitio:'',
            sitio:'',
            sitiosInteres:[],

            video:'',
            videos:[],

            textoTitulo:'',
            textoParrafo1:'',
            textoParrafo2:'',
            textoParrafo3:'',

            tituloImagen:'',
            nameImagen:'',
            fileImagen:'',
            urlImagen:'',
            imagenesMod:[],
            imagenes:[],

            urlFaceBook:'',
            urlTwitter:'',
            urlInstagram:'',
            urlYoutube:'',

            nameFooter:'',
            fileFooter:'',
            urlFooter:'',
            footer:false,
            
            
            nameFooterMod:'',
            urlFooterMod:'',
            footerMod:false,

            modalCancel: false,

        })           
    }
    

    componentDidMount(){
       this.setState({ nombre:this.props.codigoQR,
        descripcion:this.props.descripcion,
        fecini:this.props.vigenciaIni,
        fecfin:this.props.vigenciaFin,})

        const val = url.urlF+'/'+ this.props.codigoQR
        this.setState({qr:true, codigoQr:val})
        
        
        /*obtener Componentes*/
        controllerQR.obtenerComponentes(this.props.idCodigo)
        .then(resComponentes=>{
   
            if(resComponentes.data.data.success === true){   
                /*Carga Sitios*/
                const arrSitios = []
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(1)).map(arr =>
                    arrSitios.push({'nombreSitio':arr.Descripcion,'sitio':arr.Ruta}) 
                )
                this.setState({sitiosInteres:arrSitios})
               
                /*Carga Videos*/
                const arrVideos = []
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(2)).map(arr =>
                    arrVideos.push({'video':arr.Ruta}) 
                )
                this.setState({videos:arrVideos})         
              
                /*Carga texto*/
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(3) && items.Descripcion.includes('textoTitulo')).map(arr =>{
                    this.setState({textoTitulo:arr.Ruta})
                })                
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(3) && items.Descripcion.includes('textoParrafo1')).map(arr =>{
                    this.setState({textoParrafo1:arr.Ruta})
                })
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(3) && items.Descripcion.includes('textoParrafo2')).map(arr =>{
                    this.setState({textoParrafo2:arr.Ruta})
                })
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(3) && items.Descripcion.includes('textoParrafo3')).map(arr =>{
                    this.setState({textoParrafo3:arr.Ruta})
                })

                /*Carga imagenes*/
                const arrImagenesMod = []
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(4)).map(arr =>
                    arrImagenesMod.push({'tituloImagen':arr.Descripcion,'nameImagen':arr.Ruta,'urlImagen':url.pathImg+arr.Ruta }) 
                )
                this.setState({imagenesMod:arrImagenesMod})      
                
                /*carga Redes Sociales*/
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(5) && items.Descripcion.includes('FaceBook')).map(arr =>{
                    this.setState({urlFaceBook:arr.Ruta})
                })
              
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(5) && items.Descripcion.includes('Twitter')).map(arr =>{
                    this.setState({urlTwitter:arr.Ruta})
                })
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(5) && items.Descripcion.includes('Instagram')).map(arr =>{
                    this.setState({urlInstagram:arr.Ruta})
                })
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(5) && items.Descripcion.includes('Youtube')).map(arr =>{
                    this.setState({urlYoutube:arr.Ruta})
                })

                 /*Carga Footer*/       
                 resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(6)).map(arr =>              
                    this.setState({nameFooterMod:arr.Ruta,urlFooterMod:url.pathImg+arr.Ruta,footerMod:true})               
                 )   
            }
        })
        .catch(err=>{
           
        })
    }

  
    changeHandler = e => {
        this.setState({[e.target.name]: e.target.value})
    }

    generaQrCode = e => {
        this.setState({ nombre: e.target.value})
        if (e.target.value.length >= 5){
            const val = url.urlF+'/'+ e.target.value
            this.setState({qr:true, codigoQr:val})
        }else{
            this.setState({qr:false, codigoQr:''})
        }
    }

    nextStep = () =>{
        if(parseInt(this.state.activeItem) <= 6 ){
            let item = parseInt(this.state.activeItem) +1
            this.setState({activeItem:item.toString()})
        }
    }
    backStep  = () =>{
        if(parseInt(this.state.activeItem) >=2 ){
            let item = parseInt(this.state.activeItem) -1
            this.setState({activeItem:item.toString()})    
        }
    }

    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
          this.setState({
            activeItem: tab
          });
        }
    }

    toggleModal = () => {
        this.setState({modal: !this.state.modal,modalMessage:''});
    }


    /*Validador de URL*/
    isValidURL = (u)=>{
        let elm
        if(!elm){
          elm = document.createElement('input');
          elm.setAttribute('type', 'url');
        }
        elm.value = u;
        return elm.validity.valid;
    }

    /*Validador de URL*/
    isValidURL = (u)=>{
        let elm
        if(!elm){
            elm = document.createElement('input');
            elm.setAttribute('type', 'url');
        }
        elm.value = u;
        return elm.validity.valid;
    }


    /*Gestion Sitios de interes*/
    addSitiosInteres = () =>{   
        if(this.state.nombreSitio){
            if (this.isValidURL(this.state.sitio)){
                const arrSitios = this.state.sitiosInteres        
                arrSitios.push({'nombreSitio':this.state.nombreSitio,'sitio':this.state.sitio}) 
                this.setState({sitiosInteres:arrSitios})   
            }else{
                this.setState({modal:true,modalMessage:'Url invalida'})
            }
        }else{
            this.setState({modal:true,modalMessage:'Debes seleccionar un nombre para este sitio.'})
        }
        this.setState({nombreSitio:'',sitio:''})
    }
    getSitiosInteres = () => {
        return this.state.sitiosInteres.map(sitios =>          
                <PrevSitios 
                    nombreSitio = {sitios.nombreSitio}
                    url = {sitios.sitio}
                    deleteSitiosInteres = {this.deleteSitiosInteres} 
                />         
        )
    }
    deleteSitiosInteres = (url) =>{  
        const arrSitios = this.state.sitiosInteres   
        for (let i = 0; i < arrSitios.length; i++){
            if (arrSitios[i].sitio === url) {
                arrSitios.splice(i,1);
            }
            }
        this.setState({sitiosInteres:arrSitios}) 
    }
    
    /*Gestion Videos*/
    addVideos = () =>{   
        if (this.isValidURL(this.state.video)){
            const arrVideos = this.state.videos        
            arrVideos.push({'video':this.state.video}) 
            this.setState({videos:arrVideos})   
        }else{
            this.setState({modal:true,modalMessage:'Url invalida'})
        }
        this.setState({video:''})
    }
    getVideos = () => {
        return this.state.videos.map(video =>          
                <PrevVideos
                    url = {video.video}
                    deleteVideos = {this.deleteVideos} 
                />         
        )
    }
    deleteVideos = (url) =>{  
        const arrVideos = this.state.videos   
        for (let i = 0; i < arrVideos.length; i++){
            if (arrVideos[i].video === url) {
                arrVideos.splice(i,1);
            }
            }
        this.setState({videos:arrVideos}) 
    }    
    
    /*Gestion Imagenes*/
    changeHandlerImg = e => {
        this.setState({nameImagen: e.target.files[0].name,fileImagen:e.target.files[0], urlImagen:URL.createObjectURL(e.target.files[0])})     
    }   
    addImagenes = () =>{   
        /*Valida si existe la imagen en la Bd*/
        if(this.state.nameImagen){
            controllerImg.validarImagenQR(this.state.nameImagen)
            .then(respValImg =>{
                /*Si Existe == 0 entonces la puede agregar */
                if(respValImg.data.data.Imagen[0].Existe === 0){
                    const arrImagenes = this.state.imagenes        
                    arrImagenes.push({'tituloImagen':this.state.tituloImagen,'nameImagen':this.state.nameImagen,'fileImagen':this.state.fileImagen,'urlImagen':this.state.urlImagen }) 
                    this.setState({imagenes:arrImagenes,tituloImagen:'',nameImagen:'',fileImagen:'',urlImagen:''})  
                }else{
                    this.setState({modal:true,modalMessage:'Ya existe una imagen con el mismo nombre, por favor agrega una imagen diferente.'})
                }
            })
        }
    }
    getImagenesMod = () => {
        return this.state.imagenesMod.map(imagen =>          
                <PrevImagenMod
                    tituloImagen = {imagen.tituloImagen}
                    nameImagen = {imagen.nameImagen}
                    fileImagen = {imagen.fileImagen}
                    urlImagen = {imagen.urlImagen}    
                    deleteImagenMod = {this.deleteImagenMod} 
                />         
        )
    }
    getImagenes = () => {
        return this.state.imagenes.map(imagen =>          
                <PrevImagen
                    tituloImagen = {imagen.tituloImagen}
                    nameImagen = {imagen.nameImagen}
                    fileImagen = {imagen.fileImagen}
                    urlImagen = {imagen.urlImagen}    
                    deleteImagen = {this.deleteImagen} 
                />         
        )
    }

    deleteImagenMod = (nameImagen) =>{   
        const arrImagenesMod = this.state.imagenesMod   
        for (let i = 0; i < arrImagenesMod.length; i++){
            if (arrImagenesMod[i].nameImagen === nameImagen) {
                arrImagenesMod.splice(i,1);
            }
            }
        this.setState({imagenesMod:arrImagenesMod}) 
    } 
    deleteImagen = (nameImagen) =>{          
        const arrImagenes = this.state.imagenes   
        for (let i = 0; i < arrImagenes.length; i++){
            if (arrImagenes[i].nameImagen === nameImagen) {
                arrImagenes.splice(i,1);
            }
            }
        this.setState({imagenes:arrImagenes}) 
    } 
    
    /*Gestion Footer*/
    changeHandlerFooter = e => {
        this.setState({nameFooter: e.target.files[0].name,fileFooter:e.target.files[0], urlFooter:URL.createObjectURL(e.target.files[0])})  
    }
    addFooter  = () =>{       
            /*Valida si existe la imagen en la Bd*/ 
            if(this.state.urlFooterMod ==='' ){
                if(this.state.nameFooter){
                    controllerImg.validarImagenQR(this.state.nameFooter)
                    .then(respValFooter =>{
                        /*Si Existe == 0 entonces la puede agregar */
                        if(respValFooter.data.data.Imagen[0].Existe === 0){      
                            this.setState({footer:true})  
                        }else{
                            this.setState({modal:true,modalMessage:'Ya existe una imagen con el mismo nombre, por favor agrega una imagen diferente.'})
                            this.setState({nameFooter:'',fileFooter:'', urlFooter:'',footer:false}) 
                        }
                    })
                }  
            }else{
                this.setState({modal:true,modalMessage:'No se puede agregar más de una imagen en esta sección'}) 
                this.setState({nameFooter:'',fileFooter:'', urlFooter:'',footer:false})             
            }
          
    }
    getFooter = () => {
        return <PrevFooter                   
                nameFooter = {this.state.nameFooter}
                fileFooter = {this.state.fileFooter}
                urlFooter = {this.state.urlFooter}  
                deleteFooter = {this.deleteFooter} 
            />           
    }   
    getFooterMod = () => {
        return <PrevFooterMod                   
                urlFooterMod = {this.state.urlFooterMod}  
                deleteFooterMod = {this.deleteFooterMod} 
            />           
    }   
    deleteFooter = () =>{          
        this.setState({nameFooter:'',fileFooter:'', urlFooter:'',footer:false}) 
    }   
    deleteFooterMod = () =>{          
        this.setState({urlFooterMod:'',footerMod:false}) 
    }     



    /*Modificar Codigo*/
    modificarQrCode = () =>{
        this.validaSesion()
        /*valida codigo*/
        if(this.validarCodigo() === true){
            /*crea cabecera de codigo*/
            controllerQR.modificaQR(this.props.idCodigo,this.state.nombre.toString(),this.state.descripcion.toString(),'',parseInt(this.props.idusuario),this.state.fecini.toString(),this.state.fecfin.toString())
            .then(resCodigoQR=>{ 
                    if(resCodigoQR.data.data.success === true){                      
                        if(resCodigoQR.data.data.Actualiado[0].Actualizado === 1){

                            /*agrega sitios de interes*/
                            if(this.state.sitiosInteres.length > 0){
                                this.state.sitiosInteres.map(sitios =>                            
                                    controllerQR.agregaComponenteQR(this.props.idCodigo,1,sitios.nombreSitio,sitios.sitio)
                                )                                
                                setTimeout(()=>{
                                    this.setState({ nombreSitio:'',sitio:'',sitiosInteres:[]})
                                }, 1000)
                            }
                            /*agrega videos*/
                            if(this.state.videos.length > 0){
                                this.state.videos.map(video =>                            
                                    controllerQR.agregaComponenteQR(this.props.idCodigo,2,'',video.video)
                                )                               
                                setTimeout(()=>{
                                    this.setState({video:'',videos:[]})
                                }, 1000)
                            }
                            /*agrega Texto*/
                            if(this.state.textoTitulo){
                                controllerQR.agregaComponenteQR(this.props.idCodigo,3,'textoTitulo',this.state.textoTitulo)
                                setTimeout(()=>{
                                    this.setState({textoTitulo:''})
                                }, 1000)
                            }
                            if(this.state.textoParrafo1){
                                controllerQR.agregaComponenteQR(this.props.idCodigo,3,'textoParrafo1',this.state.textoParrafo1)
                                setTimeout(()=>{
                                    this.setState({textoParrafo1:''})
                                }, 1000)
                            }
                            if(this.state.textoParrafo2){
                                controllerQR.agregaComponenteQR(this.props.idCodigo,3,'textoParrafo2',this.state.textoParrafo2)
                                setTimeout(()=>{
                                    this.setState({textoParrafo2:''})
                                }, 1000)
                            }
                            if(this.state.textoParrafo3){
                                controllerQR.agregaComponenteQR(this.props.idCodigo,3,'textoParrafo3',this.state.textoParrafo3)
                                setTimeout(()=>{
                                    this.setState({textoParrafo3:''})
                                }, 1000)
                            }
                            /*agrega imagenes*/
                            if(this.state.imagenes.length > 0){
                                this.state.imagenes.map(imagen =>                               
                                    controllerImg.AgregarImg(imagen.fileImagen)
                                    .then(respImg =>{
                                        controllerQR.agregaComponenteQR(this.props.idCodigo,4,imagen.tituloImagen,imagen.nameImagen)
                                    })
                                )
                                setTimeout(()=>{
                                    this.setState({tituloImagen:'',nameImagen:'',fileImagen:'',urlImagen:'',imagenes:[]})
                                }, 1000)
                                
                            }


                            /*actualiza imagenes existentes */
                            if(this.state.imagenesMod.length > 0){
                                this.state.imagenesMod.map(imagen =>   
                                        controllerQR.modificarComponenteQR(this.props.idCodigo,4,imagen.tituloImagen,imagen.nameImagen)
                                )
                                setTimeout(()=>{
                                    this.setState({imagenesMod:[]})
                                }, 1000)                            
                            }         
                            /*agrega Redes Sociales*/
                            if(this.state.urlFaceBook){
                                controllerQR.agregaComponenteQR(this.props.idCodigo,5,'FaceBook',this.state.urlFaceBook)
                                setTimeout(()=>{
                                    this.setState({urlFaceBook:''})
                                }, 1000)
                            }
                            if(this.state.urlTwitter){
                                controllerQR.agregaComponenteQR(this.props.idCodigo,5,'Twitter',this.state.urlTwitter)
                                setTimeout(()=>{
                                    this.setState({urlTwitter:''})
                                }, 1000)
                            }
                            if(this.state.urlInstagram){
                                controllerQR.agregaComponenteQR(this.props.idCodigo,5,'Instagram',this.state.urlInstagram)
                                setTimeout(()=>{
                                    this.setState({urlInstagram:''})
                                }, 1000)
                            }
                            if(this.state.urlYoutube){
                                controllerQR.agregaComponenteQR(this.props.idCodigo,5,'Youtube',this.state.urlYoutube)
                                setTimeout(()=>{
                                    this.setState({urlYoutube:''})
                                }, 1000)
                                
                            }

                                
                            /*agrega Pie de pagina*/
                            if(this.state.nameFooter){
                                         controllerImg.AgregarImg(this.state.fileFooter)
                                .then(respFooter =>{
                                    controllerQR.agregaComponenteQR(this.props.idCodigo,6,'',this.state.nameFooter)
                                    
                                })
                                setTimeout(()=>{
                                    this.setState({ nameFooter:'',fileFooter:'',urlFooter:'',footer:false})
                                }, 1000)
                            }else{                                
                                /*actualiza footer existente */
               
                                controllerQR.modificarComponenteQR(this.props.idCodigo,6,'',this.state.nameFooterMod)                          
                                setTimeout(()=>{
                                    this.setState({ nameFooterMod:'',footerMod:false})
                                }, 1000)
                            }

                      

                            /*limpia estados de la cabecera*/
                            this.setState({ qr:false,codigoQr:'', nombre:'',descripcion:'',fecini:'',fecfin:''})   

                            this.setState({modal:true,modalMessage:'Código Actualizado correctamente'}) 
                            setTimeout(()=>{                        
                                this.setState({modal:false,modalMessage:''}) 
                                window.location='/Modificar'
                            }, 2000)


                        }else if(resCodigoQR.data.data.Actualiado[0].Actualizado === 2){
                            this.setState({modal:true,modalMessage:'Existe un codigo registrado con el mismo nombre y la misma vigencia.'})
                        }        
                    
                    }else{
                        this.setState({modal:true,modalMessage:'Ocurrieron errores, por favor intenta nuevamente'})
                    }  
                    
                    

            })
            .catch(err=>{          
                this.setState({modal:true,modalMessage:'Ocurrieron errores en el servidor, por favor intenta nuevamente'})
            })
        
        
        }
    }




    /*Valida que el nombre no tenga espacios en blanco*/
    validarCodigo = (e) => {   
    if(this.state.nombre){
    if (/\s/.test(this.state.nombre)) {
        alert("El nombre no debe contener espacios.")
        return false                
        }else{
            return true
        }
    }else{
    alert("Debes ingresar el nombre.")
    return false
    }     
    }
    /*Validar Sesion*/
    validaSesion =()=>{        
    const key = localStorage.getItem("Authorization") 
    const jwtkey = jwt(key)
    var dateNow = new Date();
    if(jwtkey.exp < (dateNow.getTime()/1000)){
        this.setState({modal:true,modalMessage:'Tu sesión ha expirado, vuelve a ingresar tus datos de acceso.'})        
        setTimeout(()=>{         
            this.setState({modal:false})
                window.localStorage.clear()
                window.location='/'
        }, 2000)
    }   
    }


    cancelarQrCode= () =>{
        window.location='/Modificar'
    }


    cancelModal = () => {
        this.setState({modalCancel: !this.state.modalCancel});
    }



    render(){
        return(        
               
            <MDBContainer fluid >               

                <MDBRow>
                    <MDBCol size='8'>
                        <MDBNav className='nav-tabs'>
                            <MDBNavItem>
                                <MDBNavLink to='#' active={this.state.activeItem === '1'} onClick={this.toggle('1')} role='tab' >
                                <h5>Información general</h5>
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to='#' active={this.state.activeItem === '2'} onClick={this.toggle('2')} role='tab' >
                                <h5>Sitios de interés</h5>
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to='#' active={this.state.activeItem === '3'} onClick={this.toggle('3')} role='tab' >
                                <h5>Sección de videos</h5>
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to='#' active={this.state.activeItem === '4'} onClick={this.toggle('4')} role='tab' >
                                <h5>Texto informativo</h5>
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to='#' active={this.state.activeItem === '5'} onClick={this.toggle('5')} role='tab' >
                                <h5>Sección de imágenes</h5>
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to='#' active={this.state.activeItem === '6'} onClick={this.toggle('6')} role='tab' >
                                <h5>Redes sociales</h5>
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to='#' active={this.state.activeItem === '7'} onClick={this.toggle('7')} role='tab' >
                                <h5>Pie de página</h5>
                                </MDBNavLink>
                            </MDBNavItem>
                        </MDBNav>
                        <MDBTabContent activeItem={this.state.activeItem}>                               
                            <MDBTabPane tabId='1' role='tabpanel'>
                                <MDBContainer fluid>  
                                <br/>
                                    <MDBRow>
                                        <MDBCol sm='4'> 
                                            <MDBInput  
                                                name = "nombre"
                                                label='Nombre'
                                                outline 
                                                size='md'        
                                                validate
                                                required
                                                className="black-text"
                                                value={this.state.nombre} 
                                                onChange={this.generaQrCode}
                                            />                   
                                        </MDBCol>
                                        <MDBCol sm='7'> 
                                            <MDBInput  
                                                name = "descripcion"
                                                label='Descripción'
                                                outline 
                                                size='md'    
                                                className="black-text"
                                                value={this.state.descripcion} onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>
                                    </MDBRow> 
                                    <MDBRow>
                                        <MDBCol sm='4'> 
                                            <MDBInput  
                                                name = "fecini"
                                                label='Fecha Inicio (dd/mm/aaaa)'
                                                outline 
                                                size='md'        
                                                validate
                                                required
                                                className="black-text"
                                                value={this.state.fecini} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>
                                        <MDBCol sm='4'> 
                                            <MDBInput  
                                                name = "fecfin"
                                                label='Fecha Fin (dd/mm/aaaa)'
                                                outline 
                                                size='md'        
                                                validate
                                                required
                                                className="black-text"
                                                value={this.state.fecfin} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>
                                    </MDBRow>   
                                </MDBContainer>
                            </MDBTabPane>
                            <MDBTabPane tabId='2' role='tabpanel'>
                                <MDBContainer fluid > 
                                <br/>
                                    <MDBRow>                                      
                                        <MDBCol sm='3' className="align-self-center"> 
                                            <MDBInput  
                                                name = "nombreSitio"
                                                label='Nombre del Sitio'
                                                outline 
                                                size='md'        
                                                validate
                                                required
                                                className="black-text"
                                                value={this.state.nombreSitio} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>
                                        <MDBCol sm='4'  className="align-self-center"> 
                                            <MDBInput  
                                                name = "sitio"
                                                label='Sitio'
                                                outline 
                                                size='md'        
                                                validate
                                                required
                                                className="black-text"
                                                value={this.state.sitio} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>
                                        <MDBCol sm='2'  className="align-self-center">                                                
                                            <MDBBtn  
                                                outline 
                                                color="success" 
                                                size='sm'
                                                onClick={this.addSitiosInteres}          
                                            >
                                                <MDBIcon icon="plus-square" size="3x"/>
                                            </MDBBtn>    

                                        </MDBCol>                                            
                                    </MDBRow> 
                                    <MDBRow>
                                        <MDBCol sm='2'/> 
                                        <MDBCol sm='12'>
                                            {this.getSitiosInteres()}
                                        </MDBCol>
                                        
                                    </MDBRow> 
                                                            
                                </MDBContainer>                               
                            </MDBTabPane>
                            <MDBTabPane tabId='3' role='tabpanel'>
                                <MDBContainer fluid>   
                                <br/>                       
                                    <MDBRow>                    
                                        <MDBCol sm='5' className="align-self-center"> 
                                            <MDBInput  
                                                name = "video"
                                                label='Url Video'
                                                outline 
                                                size='md'        
                                                validate
                                                required
                                                className="black-text"
                                                value={this.state.video} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>
                                        <MDBCol sm='5'  className="align-self-center">                                                
                                            <MDBBtn  
                                                outline 
                                                color="success" 
                                                size="sm"
                                                onClick={this.addVideos}          
                                            >
                                                <MDBIcon icon="plus-square" size="3x"/>
                                            </MDBBtn>    

                                        </MDBCol>                                            
                                    </MDBRow> 
                                    <MDBRow>
                                        <MDBCol sm='1'/> 
                                        <MDBCol sm='12'>
                                            {this.getVideos()}
                                        </MDBCol>
                                        
                                    </MDBRow> 
                                </MDBContainer>                               
                            </MDBTabPane>
                            <MDBTabPane tabId='4' role='tabpanel'>
                                <MDBContainer fluid>  
                                <br/>
                                    <MDBRow>                                     
                                        <MDBCol sm='8'> 
                                            <MDBInput  
                                                name = "textoTitulo"
                                                label='Titulo'
                                                outline 
                                                size='md'                                              
                                                className="black-text"
                                                value={this.state.textoTitulo} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>                                                                                    
                                    </MDBRow>  
                                    <MDBRow>                                  
                                        <MDBCol sm='8'> 
                                            <MDBInput  
                                                name = "textoParrafo1"
                                                label='Parrafo1'
                                                outline 
                                                size='md'        
                                                type="textarea"
                                                className="black-text"
                                                value={this.state.textoParrafo1} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>                                                                                    
                                    </MDBRow>                                 
                                    <MDBRow>
                                
                                        <MDBCol sm='8'> 
                                            <MDBInput  
                                                name = "textoParrafo2"
                                                label='Parrafo2'
                                                outline 
                                                size='md'        
                                                type="textarea"
                                                className="black-text"
                                                value={this.state.textoParrafo2} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>                                                                                    
                                    </MDBRow>
                                    <MDBRow>                                 
                                        <MDBCol sm='8'> 
                                            <MDBInput  
                                                name = "textoParrafo3"
                                                label='Parrafo3'
                                                outline 
                                                size='md'        
                                                type="textarea"
                                                className="black-text"
                                                value={this.state.textoParrafo3} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>                                                                                    
                                    </MDBRow>     
                                </MDBContainer>                               
                            </MDBTabPane>
                            <MDBTabPane tabId='5' role='tabpanel'>
                                <MDBContainer fluid>  
                                <br/>
                                    <MDBRow  >                                      
                                        <MDBCol sm='3' className="align-self-center"> 
                                            <MDBInput  
                                                name = "tituloImagen"
                                                label='Titulo de la Imagen'
                                                outline 
                                                size='md'        
                                                validate
                                                required
                                                className="black-text"
                                                value={this.state.tituloImagen} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>
                                        <MDBCol sm='4' className="align-self-center"> 
                                            <input 
                                            type="file" 
                                            className="custom-file-input" 
                                            onChange={this.changeHandlerImg} />
                                            <label 
                                            className="custom-file-label" 
                                            htmlFor="inputGroupFile01">
                                                {this.state.nameImagen}
                                            </label>
                                        </MDBCol>
                                        <MDBCol sm='3' className="align-self-center">                                                
                                            <MDBBtn  
                                                outline 
                                                color="success" 
                                                size="sm"
                                                onClick={this.addImagenes}          
                                            >
                                                <MDBIcon icon="plus-square" size="3x"/>
                                            </MDBBtn>    

                                        </MDBCol>                                            
                                    </MDBRow> 
                                    <MDBRow>
                                    
                                  
                                        <MDBCol sm='10'>
                                            {this.getImagenesMod()}
                                            {this.getImagenes()}
                                        </MDBCol>
                                        
                                    </MDBRow> 
                                    <br/>                             
                                </MDBContainer>                               
                            </MDBTabPane>
                            <MDBTabPane tabId='6' role='tabpanel'>
                                <MDBContainer fluid>  
                                <br/> 
                                    <MDBRow>
                                        <MDBCol sm='5'> 
                                            <MDBInput  
                                                name = "urlFaceBook"
                                                label='FaceBook'
                                                outline 
                                                size='md'                                              
                                                className="black-text"
                                                value={this.state.urlFaceBook} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>                                                                                    
                                    </MDBRow>  
                                    <MDBRow>
                                        <MDBCol sm='5'> 
                                            <MDBInput  
                                                name = "urlTwitter"
                                                label='Twitter'
                                                outline 
                                                size='md'                                              
                                                className="black-text"
                                                value={this.state.urlTwitter} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>                                                                                    
                                    </MDBRow>  
                                    <MDBRow>
                                        <MDBCol sm='5'> 
                                            <MDBInput  
                                                name = "urlInstagram"
                                                label='Instagram'
                                                outline 
                                                size='md'                                              
                                                className="black-text"
                                                value={this.state.urlInstagram} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>                                                                                    
                                    </MDBRow>  
                                    <MDBRow>
                                        <MDBCol sm='5'> 
                                            <MDBInput  
                                                name = "urlYoutube"
                                                label='Youtube'
                                                outline 
                                                size='md'                                              
                                                className="black-text"
                                                value={this.state.urlFaurlYoutubeceBook} 
                                                onChange={this.changeHandler}
                                            />                   
                                        </MDBCol>                                                                                    
                                    </MDBRow>  

                                </MDBContainer>                               
                            </MDBTabPane>  
                            <MDBTabPane tabId='7' role='tabpanel'>
                                <MDBContainer fluid>  
                                    <br/>
                                    <MDBRow>  
                                        <MDBCol sm='4' > 
                                            <input 
                                            type="file" 
                                            className="custom-file-input" 
                                            onChange={this.changeHandlerFooter} />
                                            <label 
                                            className="custom-file-label" 
                                            htmlFor="inputGroupFile02">
                                                {this.state.nameFooter}
                                            </label>
                                        </MDBCol>
                                        <MDBCol sm='3' className="align-self-center">                                                
                                            <MDBBtn  
                                                outline 
                                                color="success" 
                                                size="sm"
                                                onClick={this.addFooter}          
                                            >
                                                <MDBIcon icon="plus-square" size="3x"/>
                                            </MDBBtn>    

                                        </MDBCol>    
                                                                                
                                    </MDBRow> 
                                        <br/>
                                    <MDBRow>                                     
                                        <MDBCol sm='11'>

                                         {this.state.footerMod && this.getFooterMod()}
                                         {this.state.footer && this.getFooter()}


                                        </MDBCol>
                                    </MDBRow> 
                                    <br/>                             
                                </MDBContainer>                               
                            </MDBTabPane>
                                   
                        </MDBTabContent>  
                        <br/>





                        {/* Botones adelante y atras */}
                        <MDBContainer fluid >
                            <MDBRow>                         
                                <MDBCol>                                                
                                    <MDBBtn  
                                        outline 
                                        color="success" 
                                        size="lg"
                                        onClick={this.backStep}>   
                                        <MDBIcon icon="arrow-left" size="2x"/>
                                    </MDBBtn>                                           
                                </MDBCol>
                                <MDBCol>
                                    <MDBBtn  
                                        outline 
                                        color="success" 
                                        size="lg"
                                        onClick={this.nextStep}>   
                                        <MDBIcon icon="arrow-right" size="2x"/>
                                    </MDBBtn> 
                                </MDBCol>
                            </MDBRow> 
                        </MDBContainer>

                      </MDBCol>                     
                    <br/>
                      
                      {/* Codigo QR */}
                      <MDBCol>                           
                        <MDBRow>  
                            <MDBContainer >
                                <p className='h2 text-center mb-6'>Código QR</p>
                            </MDBContainer>
                        </MDBRow>  
                        <MDBRow>  
                            <MDBContainer className ="d-flex justify-content-center"  >
                                {this.state.qr && 
                                    <MDBCard color="elegant-color">
                                        <MDBCardBody>
                                        
                                            <QRCode
                                                id= {this.state.nombre}
                                                value={this.state.codigoQr}
                                                size={300}
                                                level={"L"}
                                                includeMargin={true}
                                                bgColor = "#FFFFFF"
                                                fgColor= "#000000"
                                            />
                                        
                                        </MDBCardBody>
                                    </MDBCard>
                                }
                            </MDBContainer>
                        </MDBRow>  
                        <br/>
                        <MDBRow>                 
                                <MDBCol className ="d-flex justify-content-center" >
                                    <MDBBtn  
                                       // outline 
                                        color="success" 
                                        size="md"
                                        onClick={this.modificarQrCode}>   
                                    Actualizar
                                    </MDBBtn> 
                                </MDBCol>
                                <MDBCol className ="d-flex justify-content-center" >
                                <MDBBtn  
                                  //  outline 
                                    color="danger" 
                                    size="md"
                                    onClick={this.cancelModal}>   
                                    Cancelar
                                </MDBBtn> 
                            </MDBCol>                
                        </MDBRow>                                           
                    </MDBCol>      
                  </MDBRow>  
           
                
                 {/* Mensajes */}
                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <MDBModalHeader toggle={this.toggleModal}>Aviso </MDBModalHeader>
                    <MDBModalBody>
                             {this.state.modalMessage}   
                    </MDBModalBody>                   
                </MDBModal>

                <MDBModal isOpen={this.state.modalCancel} toggle={this.cancelModal}>
                    <MDBModalHeader toggle={this.cancelModal}>Aviso</MDBModalHeader>
                    <MDBModalBody>
                    Confirma si requieres cancelar la modificación del código QR
                    </MDBModalBody>
                    <MDBModalFooter>
                       <MDBBtn  
                            //outline 
                            color="success" 
                            size="md"
                            onClick={this.cancelModal}>   
                           Cerrar
                        </MDBBtn>  
                        <MDBBtn  
                            //outline 
                            color="danger" 
                            size="md"
                            onClick={this.cancelarQrCode}>   
                           Confirmar
                        </MDBBtn> 
                    </MDBModalFooter>
                </MDBModal>
                            
                </MDBContainer>
      
        ) 
    }
}
        
export default ModTabs1