import axios from 'axios'
const Key = '34762b8731964990995b8a61b9e83fe8'

const Api = {
     getIp: async () => {    
          return await axios.get('https://api.ipify.org/?format=json') 
     },




     getLocalizacion: async (ip) => {    
          return await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey='+Key+'&ip='+ip) 
     },


}
export default Api