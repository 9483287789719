import React, { Component } from "react";
import { MDBContainer,MDBRow ,MDBIcon,MDBCol } from
"mdbreact";


class Componente5 extends Component {

    constructor(props){
        super(props)         
        this.state = {           
            urlFacebook :'',
            muestraFacebook :false,
            urlTwitter :'',
            muestraTwitter : false,
            urlInstagram :'',
            muestraInstagram : false,
            urlYoutube :'',
            muestraYoutube : false,
          } 
    }

    
    componentDidMount(){
        for (let i = 0; i < this.props.componente5.length; i++){         
            let item = this.props.componente5[i].Descripcion
            switch(item){
                case 'FaceBook': 
                        this.setState({urlFacebook:this.props.componente5[i].Ruta,muestraFacebook:true})
                    break
                case 'Twitter': 
                        this.setState({urlTwitter:this.props.componente5[i].Ruta,muestraTwitter:true})
                    break
                case 'Instagram': 
                        this.setState({urlInstagram:this.props.componente5[i].Ruta,muestraInstagram:true})
                    break
                case 'Youtube': 
                        this.setState({urlYoutube:this.props.componente5[i].Ruta,muestraYoutube:true})
                    break
            }
        
        }
    }



    clickFace = ()=>{
        window.open(this.state.urlFacebook)
    }
    clickTwitter = ()=>{
        window.open(this.state.urlTwitter)
    }
    clickInstagram =()=>{
        window.open(this.state.urlInstagram)
    }  
    clickYoutube = ()=>{
        window.open(this.state.urlYoutube)
    }


    render() {
        return (  
            <MDBContainer>
                <MDBRow className='temp5RedSoc'>
                    <MDBCol size="12"> <h4>Síguenos en: @ZermatOficial</h4></MDBCol>
                    {this.state.muestraFacebook && 
                        <MDBCol size="1"> 
                            <MDBIcon fab icon="facebook-square" onClick={this.clickFace}/>
                        </MDBCol>
                    }
                    {this.state.muestraTwitter && 
                        <MDBCol size="1"> 
                            <MDBIcon fab icon="twitter-square" onClick={this.clickTwitter}/>
                        </MDBCol>
                    }
                    {this.state.muestraInstagram && 
                        <MDBCol size="1"> 
                            <MDBIcon fab icon="instagram" onClick={this.clickInstagram} />
                        </MDBCol>
                    }
                    {this.state.muestraYoutube && 
                        <MDBCol size="1"> 
                            <MDBIcon fab icon="youtube" onClick={this.clickYoutube} />
                        </MDBCol>   
                     }
                </MDBRow>         
            </MDBContainer>
        )
    }
}

export default Componente5;