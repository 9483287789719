import React, {Component} from 'react';


import { MDBContainer, MDBRow,MDBCol,MDBBtn
    ,MDBIcon
} from 'mdbreact';


class PrevImagenMod extends Component{    


    deleteImagenMod = () => {   
        this.props.deleteImagenMod(this.props.nameImagen)       
    }
    
    render(){
        return(  
            <MDBContainer size="md">
               <MDBRow  className='areaImagenPrevMod'>
                    <MDBCol sm='4' >
                        <img style={{ width: "150px" ,height: "120px"}} src={this.props.urlImagen}
                            alt=  {this.props.urlImagen} />
                    </MDBCol> 
                    <MDBCol sm='3' >
                        <MDBRow>
                            {this.props.tituloImagen}
                        </MDBRow>
                        <MDBRow>
                        {this.props.nameImagen}
                        </MDBRow>                   
                    </MDBCol>                   
                    <MDBCol sm='2' >
                        <MDBBtn  
                            outline 
                            color="danger" 
                            size="sm"
                            onClick={this.deleteImagenMod}          
                            >
                            <MDBIcon icon="trash-alt" size="2x"/>
                        </MDBBtn>
                    </MDBCol>
               
                    </MDBRow> 
                    <br/> 
            </MDBContainer>
         ) 
        }
}
        
export default PrevImagenMod