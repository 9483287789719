import React, {Component} from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,  MDBAlert,MDBCard} from 'mdbreact';
import Controller from '../../controllers/LoginQR';

class LoginQR extends Component{

  constructor(props){
    super(props);
    this.state=({
      usuario:'', 
      contrasenia:'',
      token:'', 
      acceso:false,
      message:false,
    })
    
  
    this.validacion = this.validacion.bind(this);
  }

  componentDidMount(){

    window.localStorage.clear()    
    document.body.style.background =`#FFFFFF`



  }

  changeHandler = event => {this.setState({ [event.target.name]: event.target.value, message:false});};

  validacion(){
    if(this.state.usuario !=='' && this.state.contrasenia !== '') {return true}
    else{return false};
  }

  submitHandler = event => {
    event.preventDefault();
    if (this.validacion()){
      event.target.className += "was-validated";
      const data = Controller.fechDataUsuario(this.state.usuario, this.state.contrasenia);
      data.then(resp=>{
        if(resp.data.success){

          this.setState({acceso:true, token:resp.data.data})

          window.localStorage.setItem('Authorization', resp.data.data)
          window.location = '/Home';



        }else{
          this.setState({acceso:false, message:true})
        }
      }).catch(err=>{
        this.setState({acceso:false, message:true})
      })
    }
  }

    render(){
        return(
          
          
         <div className ={'login'}>   

            <MDBContainer className="red-text">    
            <MDBCard className="card-body" style={{ width: "22rem", marginTop: "1rem" }}>
              <MDBRow>
                <MDBCol sm='10' >
                  <form className="needs-validation" onSubmit={this.submitHandler}>
                    <br /><br />
                    <p className="h2 text-center mb-6">Admnistrador de contentido QR ZI</p>
                    <br />

                    {this.state.message && 
                        <MDBAlert sm="12" color="info" className="justify-content-center" >
                            <h4 className="alert-heading text-center">Acceso denegado!</h4>
                            <hr />
                            <p>Usuario y/o contraseña incorrectos, favor de verificar!</p>
                          </MDBAlert>
                    }
                    
                    <MDBInput
                        name = "usuario"
                        label="Usuario"
                        icon="user"
                        outline 
                        size="lg"        
                        validate
                        required
                        error="wrong"
                        success="right"
                        className="black-text"
                        value={this.state.usuario} onChange={this.changeHandler}
                      />
              
                      <MDBInput
                          name = "contrasenia"
                          label="Password"
                          icon="lock"
                          outline 
                          size="lg"  
                          required
                          type="password"
                          className="black-text"
                          validate
                          value={this.state.contrasenia} onChange={this.changeHandler}
                        />

                      <div className="text-center">
                          <MDBBtn type="submit" className="btn-red" >Login</MDBBtn>
                      </div>

                
                  </form>
                </MDBCol>
              </MDBRow>
              </MDBCard>
            </MDBContainer>

         </div>
          
      )




    }
}


export default LoginQR;