import React, { Component } from "react";
import {  MDBContainer } from
"mdbreact";


class Componente3 extends Component {


render() {
    return (  
        <div >
        <MDBContainer>
            <section className="text-justify my-5">
                <h4 className="white-text">
                  {this.props.componente3[0] && this.props.componente3[0].Ruta}
                </h4>
          
                <h6 className="white-text">
                    {this.props.componente3[1] && this.props.componente3[1].Ruta}
                </h6>
                <h6 className="white-text">
                    {this.props.componente3[2] && this.props.componente3[2].Ruta}
                </h6>        
                <h6 className="white-text">
                    {this.props.componente3[3] && this.props.componente3[3].Ruta}
                </h6>          
              </section>
        </MDBContainer>
        </div>

    );
  }
}

export default Componente3;