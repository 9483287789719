import React, {Component} from 'react';
import { MDBContainer,MDBRow,MDBCol,MDBInput,MDBBtn
 } from "mdbreact";
import jwt from 'jwt-decode'

import NavBar from '../../NavBar'

import ModTemplate1 from '../Mod-Template1'
import ModTemplate2 from '../Mod-Template2'
import ModTemplate3 from '../Mod-Template3'

import controllerQR from '../../../../controllers/CodigoQR'

class Modificar extends Component{    
    constructor(props){
    super(props);
        this.state=({
            idusuario:'',
            templatesUsrBK:[],
            templatesUsr:[],
          
            tipoTemplate: '0',
            select:'0',
            idCodigo: '0',
            codigoQR: '',
            urlQR: '',
            descripcion: '',
            vigenciaIni: '',
            vigenciaFin: '',


            modal: false,
            modalMessage: '',

            tipoFiltro:'',

            codigoBusqueda:'',

        })
    }


    componentDidMount(){
        document.body.style.background =`#FFFFFF`

        document.getElementById("opcSelect").disabled = true

        if(!localStorage.getItem("Authorization")){
            this.setState({modal:true,modalMessage:'Tu sesión ha expirado, vuelve a ingresar tus datos de acceso.'})        
            setTimeout(()=>{         
                this.setState({modal:false})
                    window.localStorage.clear()
                    window.location='/'
                }, 2000)   
          }else{
            const key = localStorage.getItem("Authorization") 
            const jwtkey = jwt(key)
            var dateNow = new Date()
            if(jwtkey.exp < (dateNow.getTime()/1000)){
                this.setState({modal:true,modalMessage:'Tu sesión ha expirado, vuelve a ingresar tus datos de acceso.'})        
                setTimeout(()=>{         
                    this.setState({modal:false})
                        window.localStorage.clear()
                        window.location='/'
                    }, 2000)    
            }else{
                this.setState({idusuario:jwtkey.user.Id})
                /*aqui si se conecta*/
                //this.template(jwtkey.user.Id)  

            } 
        }
    }

 


    changeFiltro = (e) =>{ 
        //console.log(e.target.value)
        this.setState({tipoFiltro:e.target.value})
        //this.template(this.state.idusuario.toString(),e.target.value.toString()) 
    }

    changeHandler = e => {
        this.setState({[e.target.name]: e.target.value})
    }

    buscarCodigo = () =>{ 
        console.log('Buscar')
        this.template(this.state.idusuario.toString(),this.state.tipoFiltro.toString()) 
    }

    nuevaBusqueda = () =>{ 
        window.location='/Modificar'
    }



    template = (usuario,tipo) =>{ 

        document.getElementById("opcSelFiltro").disabled = true
        
        document.getElementById("opcSelect").disabled = false

        controllerQR.obtenerTemplateUsuario(usuario,tipo)
        .then(resTempUsr=>{
           console.log(resTempUsr.data.data.Templates)
            if(resTempUsr.data.data.success === true){
                //resTempUsr.data.data.Templates.filter(data => data.Codigo.includes(this.state.codigoBusqueda))
                this.setState( {templatesUsrBK:resTempUsr.data.data.Templates.filter(data => data.Codigo.includes(this.state.codigoBusqueda))})   
                
                
            }else{
                this.setState({templatesUsrBK:[]})
            }
        })
       
    }

    
    getOption =()=>{
        return this.state.templatesUsrBK.map(opciones =>          
            <option value={opciones.IdCodigo}>{opciones.Codigo} - {opciones.Descripcion}</option>    
        )
    }

    changeOption = (e) =>{   
  
   
        if(e.target.value > 0){
            this.setState({select:this.state.idCodigo})
            const filterTemplate =  this.state.templatesUsrBK.filter(templates => templates.IdCodigo.includes(e.target.value))
            this.setState({ 
                            tipoTemplate:filterTemplate[0].TipoTemplate,
                            idCodigo:filterTemplate[0].IdCodigo,
                            codigoQR:filterTemplate[0].Codigo,
                            urlQR:filterTemplate[0].UrlQR,
                            descripcion:filterTemplate[0].Descripcion,
                            vigenciaIni:filterTemplate[0].VigenciaIni,
                            vigenciaFin:filterTemplate[0].VigenciaFin,
                        })
        }

    }



    
    render(){
            return(  
                <div >  
                    <NavBar/> 
                    <br />

                    <MDBContainer>
                        <MDBRow>
                            <MDBCol lg="12">
                                <p className="h2 text-center mb-6">Modificar códigos QR</p>            
                            </MDBCol>
                        </MDBRow> 
                        <br/>     
                        <MDBRow>
                            <MDBCol sm="3" >                        
                                <select className="browser-default custom-select" id='opcSelFiltro' onChange={this.changeFiltro}  >
                                    <option value="0">Selecciona una opción</option>
                                    <option value="1">Vencidos</option>
                                    <option value="2">Vigentes</option>
                                    <option value="3">Próximos</option>
                                    <option value="4">Todos</option>
                                </select>
                            </MDBCol>
                           
                            <MDBCol sm="2" >    
                                <MDBInput  
                                    name = "codigoBusqueda"
                                    label='Codigo QR'
                                    outline 
                                    size='md'    
                                    className="black-text"
                                    value={this.state.codigoBusqueda} onChange={this.changeHandler}
                                />  
                            </MDBCol>

                            <MDBCol sm="2" > 
                                <MDBBtn  
                                    outline 
                                    color="success" 
                                    size="md"
                                    onClick={this.buscarCodigo}>   
                                   Buscar
                                </MDBBtn>   
                            </MDBCol>

                            <MDBCol sm="3">                        
                                <select className="browser-default custom-select"  id = 'opcSelect' onChange={this.changeOption}  >
                                    <option>Selecciona una opción</option>
                                    {this.getOption()}
                                </select>
                            </MDBCol>
                        
                            <MDBCol sm="1" > 
                                <MDBBtn  
                                    outline 
                                    color="danger" 
                                    size="md"
                                    onClick={this.nuevaBusqueda}>   
                                   Reiniciar
                                </MDBBtn>   
                            </MDBCol>
                    
                        </MDBRow>                
                    </MDBContainer>
                    <br />


                    {this.state.tipoTemplate === "1" &&   <ModTemplate1 idCodigo = {this.state.idCodigo}
                    codigoQR = {this.state.codigoQR}
                    urlQR = {this.state.urlQR}
                    descripcion = {this.state.descripcion}
                    vigenciaIni = {this.state.vigenciaIni}
                    vigenciaFin = {this.state.vigenciaFin}
                    idusuario = {this.state.idusuario}
                    />} 

                    {this.state.tipoTemplate === "2"   &&   <ModTemplate2 idCodigo = {this.state.idCodigo}
                    codigoQR = {this.state.codigoQR}
                    urlQR = {this.state.urlQR}
                    descripcion = {this.state.descripcion}
                    vigenciaIni = {this.state.vigenciaIni}
                    vigenciaFin = {this.state.vigenciaFin}
                    idusuario = {this.state.idusuario}
                    />} 


                    {this.state.tipoTemplate === "3"  &&   <ModTemplate3 idCodigo = {this.state.idCodigo}
                    codigoQR = {this.state.codigoQR}
                    urlQR = {this.state.urlQR}
                    descripcion = {this.state.descripcion}
                    vigenciaIni = {this.state.vigenciaIni}
                    vigenciaFin = {this.state.vigenciaFin}
                    idusuario = {this.state.idusuario}
                    />} 
                
                   
                    
                </div>
            ) 
        }
}
        
export default Modificar