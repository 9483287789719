import API from '../api/Imagenes';

const Controler = {
    AgregarImg: async(file)=>{
        return await API.AgregarImg(file)
            .then(resp=>{
                return resp
            }).catch(err=>{
                return err
            })
    },
    validarImagenQR: async(imagen)=>{        
        return await API.validarImagenQR(imagen)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            }).finally(()=>{
            
            })
    },
  

}

export default Controler