import React, { Component } from "react";
import { MDBContainer } from "mdbreact";

import url from '../../../../api/url'

class Componente6 extends Component {

  constructor(props){
      super(props)          
  }

render() {
    return (            
        <MDBContainer>
            <img
            className="d-block w-100"
            src= {url.pathImg+this.props.componente6[0].Ruta}
            alt="Footer"
          />
        </MDBContainer>
    )
  }
}

export default Componente6