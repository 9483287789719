import React from "react";
import Video from "../RepVideo";

import Bowser from "bowser";
import controllerBit from "../../controllers/Registro";
import Ip from "../../controllers/Ip";

class Template extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         url: "",
      };
   }

   componentDidMount() {
      Ip.getIp().then((resIp) => {
         Ip.getLocalizacion(resIp).then((respGeo) => {
            if (
               respGeo.data.country_code2 === "MX" ||
               respGeo.data.country_code2 === "CR" ||
               respGeo.data.country_code2 === "NI" ||
               respGeo.data.country_code2 === "PA" ||
               respGeo.data.country_code2 === "US"
            ) {
               const browser = Bowser.getParser(window.navigator.userAgent);

               controllerBit.registroBitacoraQRUsr(
                  "/unete-whatsapp-zermat",
                  browser.parsedResult.platform.type,
                  browser.parsedResult.browser.name,
                  resIp,
                  browser.parsedResult.os.name,
               );

               this.setState({ url: "https://www.youtube.com/watch?v=BIxn5aTnRIM?rel=0" });
            }
         });
      });
   }

   clickWhatsApp = () => {
      // window.open("https://api.whatsapp.com/send?phone=+"+this.state.confGen.WhatsApp+"&text=Hola,%20soy%20la%20líder%20"+this.props.data.NomCli+"%20 y mi clave es%20"+this.props.data.Id+", por favor me podrías apoyar.")
      window.open(
         "https://api.whatsapp.com/send?phone=+525539778942&text=Hola%20me%20interesa%20unirme,%20para%20recibir%20información.",
      );
   };

   render() {
      return (
         <div>
            <div className={`body ${this.state.loading} ${this.state.isArticleVisible ? "is-article-visible" : ""}`}>
               <div className="video-backgroundWa">
                  <div className="video-foregroundWa">
                     <Video url={this.state.url} />
                  </div>
               </div>
            </div>

            <div className="WhatsApp">
               <a onClick={this.clickWhatsApp}>
                  <p>
                     {" "}
                     Da clic y únete <i class="fab fa-whatsapp"></i>{" "}
                  </p>
               </a>
            </div>
         </div>
      );
   }
}

export default Template;
