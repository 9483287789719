import React, {Component} from 'react';


import { MDBContainer, MDBRow,MDBCol,MDBBtn
    ,MDBIcon
} from 'mdbreact';


class PrevSliderMod extends Component{    

  

    deleteImagenMod = () => {   
        this.props.deleteImagenMod(this.props.nameImagenMod)       
    }
    
    render(){
        return(  
            <MDBContainer size="md">
               <MDBRow  className='areaSliderPrevMod'>
                    <MDBCol sm='5' >
                        <img style={{ width: "200px" ,height: "300px"}} src={this.props.urlImagenMod} 
                         alt=  {this.props.urlImagenMod}/>
                    </MDBCol> 
                    <MDBCol sm='3' >
                        
                        <MDBRow>
                        {this.props.nameImagenMod}
                        </MDBRow>                   
                    </MDBCol>                   
                    <MDBCol sm='2' >
                        <MDBBtn  
                            outline 
                            color="danger" 
                            size="sm"
                            onClick={this.deleteImagenMod}          
                            >
                            <MDBIcon icon="trash-alt" size="2x"/>
                        </MDBBtn>
                    </MDBCol>
               
                    </MDBRow> 
                    <br/> 
            </MDBContainer>
         ) 
        }
}
        
export default PrevSliderMod