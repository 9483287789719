import axios from 'axios';
import url from './url';


const Api = {
    AgregarImg: async(file)=>{
        let fd = new FormData();
        fd.append('objfile', file)
        return await   axios({
            url: 'https://zermatinternal.com:3030/ImageUpload/api/ImageUpload/?tipo=2', 
            method:'POST',
            headers:{'Content-Type': 'multipart/form-data'}, 
            data: fd
        })
    },
    validarImagenQR: async(imagen)=>{        
        var data = {
            'imagen': imagen,         
        }
        return await axios.post(url.url+'/admin/validarImagenQR',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")                    
                    }
            }
        ) 
    },
}


export default Api