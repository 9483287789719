import React, {Component} from 'react';

import controllerQR from '../../../controllers/CodigoQR'

class Template3 extends Component{    
    constructor(props){
    super(props);
        this.state=({
            enlace:''
        })
    }
    
    componentDidMount(){
        controllerQR.obtenerComponentes(this.props.idCodigo)
        .then(resCodigoQR=>{
             this.setState({enlace:resCodigoQR.data.data.componentes[0].Ruta})
             this.redireccionar()
        })
    }

    redireccionar = ()=>{
        window.location.href = this.state.enlace
    }
    
    render(){
        return(  
            <div className ={'backGroundLogin'}>  
               <h1>{this.state.enlace}</h1>
            </div>
         ) 
        }
}
        
export default Template3