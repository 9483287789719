import React, {Component} from 'react';
import { MDBContainer,MDBRow,MDBCol,
    MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter,MDBBtn } from "mdbreact";
import jwt from 'jwt-decode'

import NavBar from '../../NavBar'
import NvoTemplate1 from '../Nvo-Template1'
import NvoTemplate2 from '../Nvo-Template2'
import NvoTemplate3 from '../Nvo-Template3'


class Nuevo extends Component{    
    constructor(props){
    super(props);
        this.state=({
            tipoTemplate: '0',
            idusuario:'',
            modal: false,
            modalMessage: '',
        })
      }
    


    componentDidMount(){
        document.body.style.background =`#FFFFFF`
        if(!localStorage.getItem("Authorization")){
            this.setState({modal:true,modalMessage:'Tu sesión ha expirado, vuelve a ingresar tus datos de acceso.'})        
            setTimeout(()=>{         
                this.setState({modal:false})
                    window.localStorage.clear()
                    window.location='/'
                }, 2000)   
          }else{

            const key = localStorage.getItem("Authorization") 
            const jwtkey = jwt(key)
            var dateNow = new Date()
            if(jwtkey.exp < (dateNow.getTime()/1000)){
                this.setState({modal:true,modalMessage:'Tu sesión ha expirado, vuelve a ingresar tus datos de acceso.'})        
                setTimeout(()=>{         
                    this.setState({modal:false})
                        window.localStorage.clear()
                        window.location='/'
                    }, 2000)    
            }else{
                  this.setState({idusuario:jwtkey.user.Id})
            } 
        }
    }

    handleChangeTipo = (e) =>{   
        document.getElementById("opcSelect").disabled=true
        this.setState({tipoTemplate:e.target.value})
    }
    
    toggleModal = () => {
        this.setState({modal: !this.state.modal,modalMessage:''});
    }


    render(){
        return(         
            <div >  
                <NavBar/> 
                <br />
                <MDBContainer >
                    <MDBRow>
                        <MDBCol lg="12">
                            <p className="h2 text-center mb-6">Crear nuevo código QR</p>            
                        </MDBCol>
                    </MDBRow> 
                    <br/>     
                    <MDBRow>
                    <MDBCol sm="4"/> 
                    <MDBCol sm="4">
                       
                    <select className="browser-default custom-select" id = 'opcSelect' onChange={this.handleChangeTipo}  >
                        <option>Selecciona una opción</option>
                        <option value="1">Contenido Mixto</option>
                        <option value="2">Slider de imágenes</option>
                        <option value="3">URL especificada por el usuario</option>
                    </select>
                    </MDBCol>
                </MDBRow>                
                </MDBContainer>

                <br/> 

                {this.state.tipoTemplate === "1" &&   <NvoTemplate1 idusuario = {this.state.idusuario}/>} 
                {this.state.tipoTemplate === "2" &&   <NvoTemplate2 idusuario = {this.state.idusuario}/>} 
                {this.state.tipoTemplate === "3" &&   <NvoTemplate3 idusuario = {this.state.idusuario}/>} 


                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <MDBModalHeader toggle={this.toggleModal}> Aviso </MDBModalHeader>
                    <MDBModalBody>
                             {this.state.modalMessage}   
                    </MDBModalBody>
                    <MDBModalFooter>
                       <MDBBtn  
                            outline 
                            color="warning" 
                            size="md"
                            onClick={this.toggleModal}>   
                           Cerrar
                        </MDBBtn>  
                    </MDBModalFooter>
                </MDBModal>

            </div>
         ) 
        }
}
        
export default Nuevo