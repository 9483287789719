import React, { useEffect } from "react";
import NavBar from "../NavBar";

import Bowser from "bowser";
import controllerBit from "../../../controllers/Registro";
import Ip from "../../../controllers/Ip";
import jwt from "jwt-decode";

const AdmHome = (props) => {
   useEffect(() => {
      document.body.style.background = `#FFFFFF`;

      if (!localStorage.getItem("Authorization")) {
         this.setState({ modal: true, modalMessage: "Tu sesión ha expirado, vuelve a ingresar tus datos de acceso." });
         setTimeout(() => {
            this.setState({ modal: false });
            window.localStorage.clear();
            window.location = "/";
         }, 2000);
      } else {
         const key = localStorage.getItem("Authorization");
         const jwtkey = jwt(key);
         var dateNow = new Date();
         if (jwtkey.exp < dateNow.getTime() / 1000) {
            this.setState({
               modal: true,
               modalMessage: "Tu sesión ha expirado, vuelve a ingresar tus datos de acceso.",
            });
            setTimeout(() => {
               this.setState({ modal: false });
               window.localStorage.clear();
               window.location = "/";
            }, 2000);
         } else {
            Ip.getIp().then((resIp) => {
               Ip.getLocalizacion(resIp)
                  .then((respGeo) => {
                     if (
                        respGeo.data.country_code2 === "MX" ||
                        respGeo.data.country_code2 === "CR" ||
                        respGeo.data.country_code2 === "NI" ||
                        respGeo.data.country_code2 === "PA" ||
                        respGeo.data.country_code2 === "US"
                     ) {
                        const browser = Bowser.getParser(window.navigator.userAgent);
                        controllerBit.registroBitacoraQRAdm(
                           jwtkey.user.Id,
                           "Home",
                           browser.parsedResult.platform.type,
                           browser.parsedResult.browser.name,
                           resIp,
                           browser.parsedResult.os.name,
                        );
                     }
                  })
                  .catch((err) => {
                     const browser = Bowser.getParser(window.navigator.userAgent);
                     controllerBit.registroBitacoraQRAdm(
                        jwtkey.user.Id,
                        "Home",
                        browser.parsedResult.platform.type,
                        browser.parsedResult.browser.name,
                        resIp,
                        browser.parsedResult.os.name,
                     );
                  });
            });
         }
      }
   }, []);

   return (
      <div className={"backGroundBlanco"}>
         <NavBar />
      </div>
   );
};

export default AdmHome;
