import React, {Component} from 'react';


import { MDBContainer, MDBRow,MDBCol,MDBBtn
    ,MDBIcon
} from 'mdbreact';


class PrevFooter extends Component{    
     

    deleteFooter = () => {   
        this.props.deleteFooter()       
    }
    
    render(){
        return(  
            <MDBContainer size="md">
               <MDBRow  className='areaImagenPrev'>
                    <MDBCol sm='7' >
                        <img style={{ width: "400px" ,height: "200px"}} src={this.props.urlFooter}
                        alt=  {this.props.urlFooter} />
                    </MDBCol> 
                                   
                    <MDBCol sm='2' >
                        <MDBBtn  
                            outline 
                            color="danger" 
                            size="sm"
                            onClick={this.deleteFooter}          
                            >
                            <MDBIcon icon="trash-alt" size="2x"/>
                        </MDBBtn>
                    </MDBCol>
               
                    </MDBRow> 
                    <br/> 
            </MDBContainer>
         ) 
        }
}
        
export default PrevFooter