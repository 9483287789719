/**
 * @description Controlador del Login.
 * se pasan como parámetros el usuario, el password y el país de selección.
*/
import API from '../api/Registro';

const Controller = {
    registroBitacoraQRAdm: async (idusuario,accion,dispositivo,navegador,ip, os) =>{
        return await API.registroBitacoraQRAdm(idusuario,accion,dispositivo,navegador,ip, os)
        .then(resp => {
            return resp;
        })
        .catch(err =>{
            return err;
        })
    },

    registroBitacoraQRUsr: async (template,dispositivo,navegador,ip, os) =>{
        return await API.registroBitacoraQRUsr(template,dispositivo,navegador,ip, os)
        .then(resp => {
            return resp;
        })
        .catch(err =>{
            return err;
        })
    }



}

export default Controller