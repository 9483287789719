import axios from 'axios';
import url from './url';

const Api = {

    creaQR: async(idTemplate, codigo, descripcion,urlQR,usuario,vigenciaIni,vigenciaFin)=>{        
        var data = {
            'idTemplate': idTemplate,
            'codigo': codigo,
            'descripcion': descripcion, 
            'url':urlQR,
            'usuario':usuario, 
            'vigenciaIni':vigenciaIni, 
            'vigenciaFin':vigenciaFin, 
        }
        return await axios.post(url.url+'/admin/creaQR',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")                    
                    }
            }
        ) 
    },
    agregaComponenteQR: async(idCodigo, idComponente,descripcion,ruta)=>{
        var data = {
            'idCodigo': idCodigo,
            'idComponente': idComponente,
            'descripcion': descripcion, 
            'ruta':ruta,           
        }
        return await axios.post(url.url+'/admin/agregaComponenteQR',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")                    
                    }
            }
        ) 
    },
    obtenerTemplate: async(codigo)=>{
            return await axios.post(url.url+'/qr/obtenerTemplate',{'codigo': codigo} ) 
    },
    obtenerComponentes: async(idcodigo)=>{
        return await axios.post(url.url+'/qr/obtenerComponentes',{'idcodigo': idcodigo} ) 
    },
    obtenerTemplateUsuario: async(usuario,tipo)=>{        
    var data = {
        'usuario': usuario,
        'tipo': tipo,         
    }
    return await axios.post(url.url+'/admin/obtenerTemplateUsuario',
        data,
        {headers: {
                    'Content-Type': 'application/json'
                    ,'Authorization': localStorage.getItem("Authorization")                    
                }
        }
    ) 
    },

    modificaQR: async(idcodigo, codigo, descripcion,urlQR,usuario,vigenciaIni,vigenciaFin)=>{        
        var data = {
            'idcodigo': idcodigo,
            'codigo': codigo,
            'descripcion': descripcion, 
            'url':urlQR,
            'usuario':usuario, 
            'vigenciaIni':vigenciaIni, 
            'vigenciaFin':vigenciaFin, 
        }
        return await axios.post(url.url+'/admin/modificaQR',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")                    
                    }
            }
        ) 
    },

    modificarComponenteQR: async(idcodigo, idcomponente, descripcion,ruta)=>{        
        var data = {
            'idcodigo': idcodigo,
            'idcomponente': idcomponente,
            'descripcion': descripcion, 
            'ruta':ruta
        }
        return await axios.post(url.url+'/admin/modificarComponenteQR',
            data,
            {headers: {
                        'Content-Type': 'application/json'
                        ,'Authorization': localStorage.getItem("Authorization")                    
                    }
            }
        ) 
    },


    
}
export default Api