import React,  { useState,useEffect }  from 'react'
import {MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse} from "mdbreact";


const NavBar = (props) => {
    const [toggle, setToggle] = useState(false)
    useEffect(() => {
        document.body.style.background =`#FFFFFF`  
    }, [])      
    return(  
            <MDBNavbar color="red" dark expand="md" scrolling  >
                <MDBNavbarBrand>
                <h3>Administrador QR</h3>
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={() => setToggle(false)} />
                <MDBCollapse id="navbarCollapse" isOpen={toggle} navbar>
                        <MDBNavbarNav right>
                            {/* <MDBNavItem >
                                <MDBNavLink to={'/Home'}><h4>Inicio</h4></MDBNavLink>
                            </MDBNavItem> */}
                            <MDBNavItem >
                                <MDBNavLink to={'/Nuevo'}><h4>Nuevo</h4></MDBNavLink>
                            </MDBNavItem> 
                            <MDBNavItem >
                                <MDBNavLink to={'/Modificar'}><h4>Modificar</h4></MDBNavLink>
                            </MDBNavItem> 
                            <MDBNavItem >
                                <MDBNavLink to={'/'}><h4>Salir</h4></MDBNavLink>
                            </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
    )    
}        
export default NavBar