import API from '../api/Ip';


const Controller = {
    getIp: async() =>{
        return await API.getIp()     
        .then(resp => { 
            return resp.data.ip
        })
        .catch(err =>{        
            return err        
        })            
    },  
    getLocalizacion: async(ip) =>{
        return await API.getLocalizacion(ip)     
        .then(resp => {           
            return resp
        })
        .catch(err =>{      
            return err        
        })            
    },  
     
}

  
export default Controller


