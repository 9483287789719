import React, { Component } from "react";
import {
MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem,  MDBNavbarToggler, MDBCollapse, 
MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, 
} from "mdbreact";


class Componente1 extends Component {

    constructor(props){
        super(props);
            this.state=({
                isOpen: false
            })
    }

  
    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    getSitios = ()=> {
        return this.props.componente1.map(sitio =>{
            return <a className="dropdown-item" 
                    href= {sitio.Ruta} 
                    target="_blank" >
                    <h5 >{sitio.Descripcion}</h5>
                </a>            
        })
    }



render() {
    return (  
        <MDBNavbar 
            className ='temp1Menu'
            dark
            color="elegant-color"
            expand="md"
            fixed="top"
            scrolling
            transparent        
        >

            <MDBNavbarBrand  display="inline"  >            
                <img src= 'https://zermatinternal.com:3030/Mexico/Logo/Blanco.png'  
                 alt="Logo"
                width= "120px"   
                height= "8%" 
                />           
           </MDBNavbarBrand>
         
            <MDBNavbarToggler onClick={this.toggleCollapse} />
          
            <MDBCollapse id="navbarCollapse" isOpen={this.state.isOpen} navbar >

            <MDBNavbarNav right >    
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav >
                    <span ><h5>Sitios de interés</h5></span>
                  </MDBDropdownToggle>                  
                  <MDBDropdownMenu>     
                        {this.getSitios()}   
                 </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>              
            </MDBNavbarNav>
           
            </MDBCollapse>
  
          

      </MDBNavbar>
    );
  }
}

export default Componente1;