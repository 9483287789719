import React,  { useState, useEffect }  from 'react'

import { MDBContainer, MDBRow,MDBCard,MDBCardBody,MDBCol} from 'mdbreact';
    
import QRCode from 'qrcode.react'
import NavBar from '../../NavBar'

import url from '../../../../api/url';


const AdmNuevoQr = () => {

    const [codigoQr, setCodigoQr] = useState('')

    useEffect(() => {
        document.body.style.background =`#FFFFFF`  
        setCodigoQr(localStorage.getItem("CodigoQr"))
        localStorage.removeItem('CodigoQr') 
    }, [])

    return(  
        <div >  

        <NavBar/> 
        <br />
           
        <MDBContainer size="sm" >
            <MDBRow className ="d-flex justify-content-center" >     
                <MDBCol lg="8">
                <p className='h2 text-center'>{url.urlF+'/'+codigoQr}</p>
                </MDBCol>
            </MDBRow>              
            <MDBRow className ="d-flex justify-content-center">  
                <MDBCol lg="6">
                    <MDBCard color="elegant-color" >
                    <MDBCardBody className ="d-flex justify-content-center">
                        <QRCode
                             id= {codigoQr}
                            value= {url.urlF+'/'+codigoQr}
                            size={400}
                            level={"L"}
                            includeMargin={true}
                            bgColor = "#FFFFFF"
                            fgColor= "#000000"
                        />
                    </MDBCardBody>
                  </MDBCard>   
                </MDBCol>
            </MDBRow>  
 
    
         </MDBContainer>

         </div>

         )    
}
        
export default AdmNuevoQr