import React, {Component} from 'react';
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink,MDBRow,MDBCol,MDBInput ,MDBBtn,MDBCard,MDBCardBody,MDBIcon 
,MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter,

} from 'mdbreact';

import QRCode from 'qrcode.react';
import jwt from 'jwt-decode'
import controllerQR from '../../../../controllers/CodigoQR'
import url from '../../../../api/url';

import PrevVideos from '../../PrevVideos'


class ModTabs3 extends Component{    
    constructor(props){
    super(props);
        this.state=({
            activeItem: '1',
            modal: false,
            modalMessage: '',

            qr:false,
            codigoQr:'',
         
            nombre:'',
            descripcion:'',
            fecini:'',
            fecfin:'',

            urlEnlace:'',
            enlace:'',

            modalCancel: false,

        })           
    }  


    
    componentDidMount(){        
        this.setState({ nombre:this.props.codigoQR,
         descripcion:this.props.descripcion,
         fecini:this.props.vigenciaIni,
         fecfin:this.props.vigenciaFin,})
 
         const val = url.urlF+'/'+ this.props.codigoQR
         this.setState({qr:true, codigoQr:val})
         
         
         /*obtener Componentes*/
         controllerQR.obtenerComponentes(this.props.idCodigo)
         .then(resComponentes=>{
             if(resComponentes.data.data.success === true){                    
                /*Carga imagenes*/
                resComponentes.data.data.componentes.filter(items => items.IdComponente.includes(8)).map(arr =>{
                    return this.setState({ enlace:arr.Ruta,})

                })   
             }
         })
         .catch(err=>{
          
         })
    }

    generaQrCode = e => {
        this.setState({ nombre: e.target.value})
        if (e.target.value.length >= 5){
            const val = url.urlF+'/'+ e.target.value
            this.setState({qr:true, codigoQr:val})
        }else{
            this.setState({qr:false, codigoQr:''})
        }
    }
    nextStep = () =>{
        if(parseInt(this.state.activeItem) <= 1){
            let item = parseInt(this.state.activeItem) + 1
            this.setState({activeItem:item.toString()})
        }
    }
    backStep  = () =>{
        if(parseInt(this.state.activeItem) >=2){
            let item = parseInt(this.state.activeItem) -1
            this.setState({activeItem:item.toString()})    
        }
    }

    toggle = tab => e => { 
         if (this.state.activeItem !== tab) {
          this.setState({activeItem: tab});
         }
    }
    toggleModal = () => {
        this.setState({modal: !this.state.modal,modalMessage:''});
    }


    /*Validador de URL*/
    isValidURL = (u)=>{
        let elm
        if(!elm){
          elm = document.createElement('input');
          elm.setAttribute('type', 'url');
        }
        elm.value = u;
        return elm.validity.valid;
    }   
    /*Gestion Enlaces*/
    changeHandler = e => {
        this.setState({[e.target.name]: e.target.value})
    }
    addEnlace = () =>{   
        if (this.isValidURL(this.state.urlEnlace)){            
            this.setState({enlace:this.state.urlEnlace})   
        }else{
            this.setState({modal:true,modalMessage:'Url invalida'})
        }
    }  
    getEnlace = () => {
        return <PrevVideos
        url = {this.state.enlace}
        deleteVideos = {this.deleteVideos} 
    />            
    }
    deleteVideos = () =>{          
        this.setState({enlace:'',urlEnlace:''}) 
    }  
 

   /*Modificar Codigo*/
   modificarQrCode = () =>{
    this.validaSesion()
    /*valida codigo*/
    if(this.validarCodigo() === true){
        /*crea cabecera de codigo*/
        controllerQR.modificaQR(this.props.idCodigo,this.state.nombre.toString(),this.state.descripcion.toString(),'',parseInt(this.props.idusuario),this.state.fecini.toString(),this.state.fecfin.toString())
        .then(resCodigoQR=>{ 
                if(resCodigoQR.data.data.success === true){                      
                    if(resCodigoQR.data.data.Actualiado[0].Actualizado === 1){   
                            if(this.state.enlace){
                                controllerQR.agregaComponenteQR(this.props.idCodigo,8,this.state.enlace,this.state.enlace)
                                setTimeout(()=>{
                                    this.setState({enlace:'',urlEnlace:''})
                                }, 1000)
                            } 

                        /*limpia estados de la cabecera*/
                        this.setState({ qr:false,codigoQr:'', nombre:'',descripcion:'',fecini:'',fecfin:''})   

                        this.setState({modal:true,modalMessage:'Código Actualizado correctamente'}) 
                        setTimeout(()=>{                        
                            this.setState({modal:false,modalMessage:''}) 
                           window.location='/Modificar'
                        }, 2000)
                    }else if(resCodigoQR.data.data.Actualiado[0].Actualizado === 2){
                        this.setState({modal:true,modalMessage:'Existe un codigo registrado con el mismo nombre y la misma vigencia.'})
                    }    
                
                }else{
                    this.setState({modal:true,modalMessage:'Ocurrieron errores, por favor intenta nuevamente'})
                }  

        })
        .catch(err=>{          
            this.setState({modal:true,modalMessage:'Ocurrieron errores en el servidor, por favor intenta nuevamente'})
        })
    
    
    }
    }    




    /*Valida que el nombre no tenga espacios en blanco*/
    validarCodigo = (e) => {   
        if(this.state.nombre){
            if (/\s/.test(this.state.nombre)) {
                alert("El nombre no debe contener espacios.")
                return false                
             }else{
                 return true
             }
        }else{
            alert("Debes ingresar el nombre.")
            return false
        }     
    }
    /*Validar Sesion*/
    validaSesion =()=>{        
        const key = localStorage.getItem("Authorization") 
        const jwtkey = jwt(key)
        var dateNow = new Date();
        if(jwtkey.exp < (dateNow.getTime()/1000)){
            this.setState({modal:true,modalMessage:'Tu sesión ha expirado, vuelve a ingresar tus datos de acceso.'})        
            setTimeout(()=>{         
                this.setState({modal:false})
                    window.localStorage.clear()
                    window.location='/'
            }, 2000)
        }   
    }
 

    cancelarQrCode= () =>{
        window.location='/Modificar'
    }

    cancelModal = () => {
        this.setState({modalCancel: !this.state.modalCancel});
    }

    render(){
        return(        
               
            <MDBContainer fluid >               

                <MDBRow>
                    <MDBCol size='8'>
                        <MDBNav className='nav-tabs'>
                            <MDBNavItem>
                                <MDBNavLink to='#' active={this.state.activeItem === '1'} onClick={this.toggle('1')} role='tab' >
                                <h5>Información general</h5>
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink  to='#' active={this.state.activeItem === '2'} onClick={this.toggle('2')} role='tab' >
                                <h5>Enlace</h5>
                                </MDBNavLink>
                            </MDBNavItem>
                        </MDBNav>
                   
                        <MDBTabContent activeItem={this.state.activeItem} >                               
                                <MDBTabPane tabId='1' role='tabpanel'>
                                    <MDBContainer fluid>  
                                    <br/>
                                        <MDBRow>
                                            <MDBCol sm='4'> 
                                                <MDBInput  
                                                    name = "nombre"
                                                    label='Nombre'
                                                    outline 
                                                    size='md'        
                                                    validate
                                                    required
                                                    className="black-text"
                                                    value={this.state.nombre} 
                                                    onChange={this.generaQrCode}
                                                />                   
                                            </MDBCol>
                                            <MDBCol sm='7'> 
                                                <MDBInput  
                                                    name = "descripcion"
                                                    label='Descripción'
                                                    outline 
                                                    size='md'    
                                                    className="black-text"
                                                    value={this.state.descripcion} onChange={this.changeHandler}
                                                />                   
                                            </MDBCol>
                                        </MDBRow> 
                                        <MDBRow>
                                            <MDBCol sm='4'> 
                                                <MDBInput  
                                                    name = "fecini"
                                                    label='Fecha Inicio (dd/mm/aaaa)'
                                                    outline 
                                                    size='md'        
                                                    validate
                                                    required
                                                    className="black-text"
                                                    value={this.state.fecini} 
                                                    onChange={this.changeHandler}
                                                />                   
                                            </MDBCol>
                                            <MDBCol sm='4'> 
                                                <MDBInput  
                                                    name = "fecfin"
                                                    label='Fecha Fin (dd/mm/aaaa)'
                                                    outline 
                                                    size='md'       
                                                    validate
                                                    required
                                                    className="black-text"
                                                    value={this.state.fecfin} 
                                                    onChange={this.changeHandler}
                                                />                   
                                            </MDBCol>
                                        </MDBRow>   
                                    </MDBContainer>
                                </MDBTabPane>
                                <MDBTabPane tabId='2' role='tabpanel'>
                                    <MDBContainer fluid>  
                                    <br/>
                                        <MDBRow  >                                      
                                        <MDBCol sm='5'> 
                                                <MDBInput  
                                                    name = "urlEnlace"
                                                    label='Url'
                                                    outline 
                                                    size='md'                                              
                                                    className="black-text"
                                                    value={this.state.urlEnlace} 
                                                    onChange={this.changeHandler}
                                                />                   
                                            </MDBCol> 

                                            <MDBCol sm='3' className="align-self-center">                                                
                                                <MDBBtn  
                                                    outline 
                                                    color="success" 
                                                    size="sm"
                                                    onClick={this.addEnlace}          
                                                >
                                                    <MDBIcon icon="plus-square" size="3x"/>
                                                </MDBBtn>    

                                            </MDBCol>                                            
                                        </MDBRow> 
                                        <MDBRow>                                     
                                            <MDBCol sm='12'>
                                            {this.state.enlace && this.getEnlace()}
                                           
                                            </MDBCol>
                                           
                                        </MDBRow> 
                                        <br/>                             
                                    </MDBContainer>                               
                                </MDBTabPane>
                        </MDBTabContent>  
                        <br/>





                        {/* Botones adelante y atras */}
                        <MDBContainer fluid >
                            <MDBRow>                         
                                <MDBCol>                                                
                                        <MDBBtn  
                                            outline 
                                            color="success" 
                                            size="lg"
                                            onClick={this.backStep}>   
                                            <MDBIcon icon="arrow-left" size="2x"/>
                                        </MDBBtn>                                           
                                    </MDBCol>
                                 <MDBCol>
                                        <MDBBtn  
                                            outline 
                                            color="success" 
                                            size="lg"
                                            onClick={this.nextStep}>   
                                            <MDBIcon icon="arrow-right" size="2x"/>
                                        </MDBBtn> 
                                    </MDBCol>
                            </MDBRow> 
                        </MDBContainer>

                      </MDBCol>                     
                    <br/>                      
                    {/* Codigo QR */}
                    <MDBCol>                           
                        <MDBRow>  
                            <MDBContainer >
                                <p className='h2 text-center mb-6'>Código QR</p>
                            </MDBContainer>
                        </MDBRow>  
                        <MDBRow>  
                            <MDBContainer className ="d-flex justify-content-center"  >
                                {this.state.qr && 
                                    <MDBCard color="elegant-color">
                                        <MDBCardBody>
                                        
                                            <QRCode
                                                id= {this.state.nombre}
                                                value={this.state.codigoQr}
                                                size={300}
                                                level={"L"}
                                                includeMargin={true}
                                                bgColor = "#FFFFFF"
                                                fgColor= "#000000"
                                            />
                                        
                                        </MDBCardBody>
                                    </MDBCard>
                                }
                            </MDBContainer>
                        </MDBRow>
                        <br/>  
                        <MDBRow>                 
                                <MDBCol className ="d-flex justify-content-center" >
                                    <MDBBtn  
                                       // outline 
                                        color="success" 
                                        size="md"
                                        onClick={this.modificarQrCode}>   
                                    Actualizar
                                    </MDBBtn> 
                                </MDBCol>
                                <MDBCol className ="d-flex justify-content-center" >
                                <MDBBtn  
                                   // outline 
                                    color="danger" 
                                    size="md"
                                    onClick={this.cancelModal}>   
                                    Cancelar
                                </MDBBtn> 
                            </MDBCol>
                
                        </MDBRow>  
                     </MDBCol>  
               
                </MDBRow>  
           
                
                 {/* Mensajes */}
                  <MDBModal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <MDBModalHeader toggle={this.toggleModal}>Aviso </MDBModalHeader>
                    <MDBModalBody>
                             {this.state.modalMessage}   
                    </MDBModalBody>                   
                  </MDBModal>

                  <MDBModal isOpen={this.state.modalCancel} toggle={this.cancelModal}>
                    <MDBModalHeader toggle={this.cancelModal}>Aviso</MDBModalHeader>
                    <MDBModalBody>
                    Confirma si requieres cancelar la modificación del código QR
                    </MDBModalBody>
                    <MDBModalFooter>
                       <MDBBtn  
                            //outline 
                            color="success" 
                            size="md"
                            onClick={this.cancelModal}>   
                           Cerrar
                        </MDBBtn>  
                        <MDBBtn  
                            //outline 
                            color="danger" 
                            size="md"
                            onClick={this.cancelarQrCode}>   
                           Confirmar
                        </MDBBtn> 
                    </MDBModalFooter>
                </MDBModal>
                            
                </MDBContainer>
      
         ) 
    }
}
        
export default ModTabs3