import React, {Component} from 'react';

import controllerQR from '../../../controllers/CodigoQR'


import Componente1 from './Componente1/index'
import Componente2 from './Componente2/index'
import Componente3 from './Componente3/index'
import Componente4 from './Componente4/index'
import Componente5 from './Componente5/index'
import Componente6 from './Componente6/index'

class Template1 extends Component{    
    constructor(props){
    super(props);
        this.state=({
            componente1: [],
            componente2: [],
            componente3: [],
            componente4: [],
            componente5: [],
            componente6: [],

        })
    }

    componentDidMount(){
        document.body.style.background =`#000000`                
        controllerQR.obtenerComponentes(this.props.idCodigo)
        .then(resCodigoQR=>{

            const arrSitios = resCodigoQR.data.data.componentes 
            const arrCom1 = []
            const arrCom2 = []
            const arrCom3 = []
            const arrCom4 = []
            const arrCom5 = []
            const arrCom6 = []

            for (let i = 0; i < arrSitios.length; i++){         
                let item = arrSitios[i].IdComponente
                switch(item){
                    case '1': 
                        arrCom1.push(arrSitios[i])
                        break
                    case '2': 
                        arrCom2.push(arrSitios[i])
                        break
                    case '3': 
                        arrCom3.push(arrSitios[i])
                        break
                    case '4': 
                        arrCom4.push(arrSitios[i])
                        break
                    case '5': 
                        arrCom5.push(arrSitios[i])
                        break
                    case '6': 
                        arrCom6.push(arrSitios[i])
                    break
                }
            }

            this.setState({componente1:arrCom1,
                componente2:arrCom2,
                componente3:arrCom3,
                componente4:arrCom4,
                componente5:arrCom5,
                componente6:arrCom6})
        })
    }


    
    render(){
        return(  
            <div>
                <div>
                    {this.state.componente1.length > 0 && <Componente1 componente1 = {this.state.componente1}/>} 
                   
                    {this.state.componente2.length > 0 && <Componente2 componente2 = {this.state.componente2}/>} 

                    {this.state.componente3.length > 0 && <Componente3 componente3 = {this.state.componente3}/>} 

                    {this.state.componente4.length > 0 && <Componente4 componente4 = {this.state.componente4}/>} 

                    {this.state.componente5.length > 0 && <Componente5 componente5 = {this.state.componente5}/>} 

                    {this.state.componente6.length > 0 && <Componente6 componente6 = {this.state.componente6}/>} 
                
                </div>

            </div>
         ) 
        }
}
        
export default Template1