import React, { Component } from "react";

import Bowser from "bowser";

import Template1 from "../Template1";
import Template2 from "../Template2";
import Template3 from "../Template3";

import Banner from "../../Banner";

import controllerQR from "../../../controllers/CodigoQR";
import controllerBit from "../../../controllers/Registro";
import Ip from "../../../controllers/Ip";

class Template extends Component {
   constructor(props) {
      super(props);
      this.state = {
         tipoTemplate: "0",
         idCodigo: "0",
         nombreTemplate: this.props.location.pathname,
         banner: false,
      };
   }

   componentDidMount() {
      Ip.getIp().then((resIp) => {
         Ip.getLocalizacion(resIp).then((respGeo) => {
            if (
               respGeo.data.country_code2 === "MX" ||
               respGeo.data.country_code2 === "CR" ||
               respGeo.data.country_code2 === "NI" ||
               respGeo.data.country_code2 === "PA" ||
               respGeo.data.country_code2 === "US"
            ) {
               const browser = Bowser.getParser(window.navigator.userAgent);

               controllerBit.registroBitacoraQRUsr(
                  this.state.nombreTemplate.toString(),
                  browser.parsedResult.platform.type,
                  browser.parsedResult.browser.name,
                  resIp,
                  browser.parsedResult.os.name,
               );

               this.template(this.state.nombreTemplate);
            }
         });
      });
   }

   template = (template) => {
      const codigo = template.substr(1, template.length - 1);
      if (
         codigo &&
         codigo !== "Home" &&
         codigo !== "Nuevo" &&
         codigo !== "NuevoQr" &&
         codigo !== "Modificar" &&
         codigo !== "unete-whatsapp-zermat" &&
         codigo !== "banner"
      ) {
         controllerQR
            .obtenerTemplate(codigo)
            .then((resCodigoQR) => {
               if (resCodigoQR.data.data.success === true) {
                  this.setState({
                     tipoTemplate: resCodigoQR.data.data.Template[0].IdTemplate,
                     idCodigo: resCodigoQR.data.data.Template[0].IdCodigo,
                  });
               } else {
                  this.setState({ banner: true });
               }
            })
            .catch((err) => {
               this.setState({ banner: true });
            });
      }
   };

   render() {
      return (
         <div className="tem1Body">
            {this.state.tipoTemplate === "1" && <Template1 idCodigo={this.state.idCodigo} />}
            {this.state.tipoTemplate === "2" && <Template2 idCodigo={this.state.idCodigo} />}
            {this.state.tipoTemplate === "3" && <Template3 idCodigo={this.state.idCodigo} />}

            {this.state.banner && <Banner />}
         </div>
      );
   }
}

export default Template;
