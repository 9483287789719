import React, {Component} from 'react';

import { ReactTinyLink } from 'react-tiny-link'
import { MDBContainer, MDBRow,MDBCol,MDBBtn
    ,MDBIcon
} from 'mdbreact';


class PrevSitios extends Component{    
    


    deleteSitiosInteres = () => {   
        this.props.deleteSitiosInteres(this.props.url)
    }

    
    render(){
        return(  
            <MDBContainer >
               <MDBRow>
                    <MDBCol >
                        <ReactTinyLink
                        cardSize="small"
                        showGraphic={true}
                        maxLine={3}
                        minLine={1}
                        url= {this.props.url}
                        
                        />
                    </MDBCol>
                    <MDBCol className="align-self-center">
                        <MDBBtn  
                            outline 
                            color="danger" 
                            size="sm"
                            onClick={this.deleteSitiosInteres}          
                            >
                            {/* <i class="fas fa-trash-alt"></i> */}
                            <MDBIcon icon="trash-alt" size="2x"/>
                        </MDBBtn>
                    </MDBCol>
                    </MDBRow> 
            </MDBContainer>
         ) 
        }
}
        
export default PrevSitios