import React, { Component } from "react";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
"mdbreact";

import Video from '../../../RepVideo/index'

class Componente2 extends Component {



    getVideos = ()=> {
        return this.props.componente2.map((item,index) =>{
          return   <MDBCarouselItem itemId={index+1}>
                      <MDBView> 
                        <Video url = {item.Ruta}/> 
                        <MDBMask overlay="black-light" />       
                    </MDBView>
                    <MDBCarouselCaption>
                      <h6>{item.Descripcion}</h6>
                      <a href= {item.Ruta}
                       target="_blank"><p><i><small>Ver completo</small></i></p></a>  
                    </MDBCarouselCaption>
                  </MDBCarouselItem>   
        })
     
    }


render() {
    return (  
            <MDBContainer size ='12'>
                <MDBCarousel
                  activeItem={1}
                  length={this.props.componente2.length}
                  showControls={false}
                  showIndicators={true}
                  interval={11000}
                  className="z-depth-1"
                >
                  <MDBCarouselInner  >

                      {this.getVideos()}
            
                  </MDBCarouselInner>
        
                </MDBCarousel>
            </MDBContainer>

    );
  }
}

export default Componente2;