import React, {Component} from 'react';

import controllerQR from '../../../controllers/CodigoQR'


import Componente7 from './Componente7/index'

class Template2 extends Component{    
    constructor(props){
    super(props);
        this.state=({
            componente7:[]
        })
    }
    
    componentDidMount(){
        document.body.style.background =`#000000`
        controllerQR.obtenerComponentes(this.props.idCodigo)
        .then(resCodigoQR=>{
            this.setState({componente7:resCodigoQR.data.data.componentes})  
        })
              
    }

   

    
    render(){
        return(  
            <div>  
                <div className='temp2Logo'>  
                    <img src= 'https://zermatinternal.com:3030/Mexico/Logo/Blanco.png'  
                        alt="Logo"
                        width= "150px"   
                        height= "10%" 
                        /> 
                </div>

                {this.state.componente7.length > 0 && <Componente7 componente7 = {this.state.componente7}/>} 


            </div>
         ) 
        }
}
        
export default Template2