import API from '../api/CodigoQR';

const Controller ={

    creaQR: async(idTemplate, codigo, descripcion,urlQR,usuario,vigenciaIni,vigenciaFin)=>{        
        return await API.creaQR(idTemplate, codigo, descripcion,urlQR,usuario,vigenciaIni,vigenciaFin)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },
    agregaComponenteQR: async(idCodigo, idComponente,descripcion,ruta)=>{
        return await API.agregaComponenteQR(idCodigo, idComponente,descripcion,ruta)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },
    obtenerTemplate: async(codigo)=>{
        return await API.obtenerTemplate(codigo)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },
    obtenerComponentes: async(idcodigo)=>{
        return await API.obtenerComponentes(idcodigo)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },
    obtenerTemplateUsuario: async(usuario,tipo)=>{
        return await API.obtenerTemplateUsuario(usuario,tipo)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },

    modificaQR: async(idcodigo, codigo, descripcion,urlQR,usuario,vigenciaIni,vigenciaFin)=>{        
        return await API.modificaQR(idcodigo, codigo, descripcion,urlQR,usuario,vigenciaIni,vigenciaFin)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },
    modificarComponenteQR: async(idcodigo, idcomponente, descripcion,ruta)=>{        
        return await API.modificarComponenteQR(idcodigo, idcomponente, descripcion,ruta)
            .then(resp=>{
                return resp;
            }).catch(err=>{
                return err;
            })
    },

}

export default Controller