import React, { Component } from "react";
import { MDBCarousel, 
  MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
"mdbreact";

import url from '../../../../api/url'

class Componente4 extends Component {


    getImagenes = ()=> {
        return this.props.componente4.map((item,index) =>{
            return <MDBCarouselItem itemId={index+1} >
                      <MDBView>
                          <img
                              className="d-block w-100"      
                              src= {url.pathImg+item.Ruta}
                              alt= {index+1}
                            />
                          <MDBMask overlay="black-light" />
                      </MDBView>             
                    </MDBCarouselItem>

        })     
    }


render() {
    return (  
           
      <MDBContainer>
        <MDBCarousel
        activeItem={1}
        length={this.props.componente4.length}
        showControls={true}
        showIndicators={true}
        className="z-depth-1"
        >
          <MDBCarouselInner>
              {this.getImagenes()}  
          </MDBCarouselInner>
        </MDBCarousel>
      </MDBContainer>


    );
  }
}

export default Componente4;